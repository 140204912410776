import React from 'react';

const Teamwork = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <title>Teamwork icon</title>
      <defs>
        <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4cf3c6" />
          <stop offset="1" stopColor="#0aa2e7" />
        </linearGradient>
      </defs>
      <g>
        <g>
          <g>
            <path
              d="M0,179.2c0,4.71,3.814,8.533,8.533,8.533h28.151c2.483,25.745,5.922,43.213,6.144,44.339
            c0.802,3.994,4.301,6.861,8.371,6.861h110.933c2.927,0,5.649-1.502,7.219-3.977l51.021-80.802
            c8.823-12.86,9.421-51.098-5.538-66.057c-3.337-3.328-8.738-3.328-12.066,0c-3.336,3.337-3.336,8.738,0,12.066
            c8.482,8.491,7.859,38.033,3.345,44.612l-48.683,77.09H58.351c-2.33-13.926-7.151-47.42-7.151-85.333V42.667
            c0-4.702,3.823-8.533,8.533-8.533s8.533,3.831,8.533,8.533c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-8.533
            c0-4.702,3.823-8.533,8.533-8.533c4.71,0,8.533,3.831,8.533,8.533c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533V25.6
            c0-4.702,3.823-8.533,8.533-8.533s8.533,3.831,8.533,8.533v8.533c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533
            c0-4.702,3.823-8.533,8.533-8.533c4.71,0,8.533,3.831,8.533,8.533v102.4c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533
            v-102.4c0-14.114-11.486-25.6-25.6-25.6c-4.557,0-8.849,1.203-12.561,3.302C145.015,4.719,137.045,0,128,0
            s-17.015,4.719-21.572,11.836c-3.712-2.099-8.004-3.302-12.561-3.302c-9.054,0-17.016,4.719-21.572,11.836
            c-3.712-2.099-8.004-3.302-12.561-3.302c-11.11,0-20.489,7.151-24.021,17.067H8.533C3.814,34.133,0,37.956,0,42.667
            c0,4.71,3.814,8.533,8.533,8.533h25.6v85.333c0,12.023,0.469,23.509,1.169,34.133H8.533C3.814,170.667,0,174.49,0,179.2z"
            />
            <path
              d="M179.2,290.133c4.719,0,8.533-3.823,8.533-8.533v-17.067c0-4.71-3.814-8.533-8.533-8.533H42.667
            c-4.719,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-25.6h119.467v8.533
            C170.667,286.31,174.481,290.133,179.2,290.133z"
            />
            <path
              d="M277.043,169.353l80.802,51.021c5.751,3.942,16.58,6.246,28.126,6.246c14.276,0,29.662-3.516,37.931-11.785
            c3.328-3.337,3.328-8.738,0-12.066c-3.336-3.336-8.738-3.336-12.066,0c-8.482,8.499-38.033,7.859-44.612,3.345l-77.09-48.683
            V58.342c13.926-2.321,47.42-7.142,85.333-7.142h93.867c4.71,0,8.533,3.831,8.533,8.533s-3.823,8.533-8.533,8.533
            c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h8.533c4.71,0,8.533,3.831,8.533,8.533
            c0,4.702-3.823,8.533-8.533,8.533c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h8.533
            c4.71,0,8.533,3.831,8.533,8.533s-3.823,8.533-8.533,8.533h-8.533c-4.719,0-8.533,3.823-8.533,8.533
            c0,4.71,3.814,8.533,8.533,8.533c4.71,0,8.533,3.831,8.533,8.533c0,4.702-3.823,8.533-8.533,8.533h-102.4
            c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h102.4c14.114,0,25.6-11.486,25.6-25.6
            c0-4.565-1.203-8.849-3.302-12.561C507.273,145.024,512,137.045,512,128c0-9.054-4.727-17.024-11.836-21.572
            c2.099-3.712,3.302-7.996,3.302-12.561c0-9.054-4.727-17.024-11.836-21.572c2.099-3.712,3.302-7.996,3.302-12.561
            c0-11.11-7.159-20.489-17.067-24.03V8.533c0-4.71-3.814-8.533-8.533-8.533S460.8,3.823,460.8,8.533v25.6h-85.333
            c-12.023,0-23.509,0.469-34.133,1.169V8.533c0-4.71-3.814-8.533-8.533-8.533c-4.719,0-8.533,3.823-8.533,8.533v28.151
            c-25.745,2.483-43.221,5.922-44.339,6.144c-3.985,0.802-6.861,4.301-6.861,8.371v110.933
            C273.067,165.06,274.569,167.782,277.043,169.353z"
            />
            <path
              d="M332.8,221.867c-4.719,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.814,8.533,8.533,8.533h136.533
            c4.719,0,8.533-3.823,8.533-8.533v-34.133c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v25.6H341.333V230.4
            C341.333,225.69,337.519,221.867,332.8,221.867z"
            />
            <path
              d="M256,42.667c0-4.71-3.814-8.533-8.533-8.533h-34.133c-4.719,0-8.533,3.823-8.533,8.533c0,4.71,3.814,8.533,8.533,8.533
            h25.6v119.467H230.4c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h17.067c4.719,0,8.533-3.823,8.533-8.533V42.667z"
            />
            <path
              d="M512,332.8c0-4.71-3.814-8.533-8.533-8.533h-28.151c-2.483-25.745-5.922-43.213-6.153-44.339
            c-0.794-3.994-4.292-6.861-8.363-6.861H349.867c-2.927,0-5.649,1.502-7.219,3.977l-51.021,80.802
            c-8.823,12.86-9.421,51.098,5.538,66.057c3.336,3.328,8.738,3.328,12.066,0c3.337-3.336,3.337-8.738,0-12.066
            c-8.482-8.491-7.859-38.033-3.345-44.612l48.683-77.09h99.081c2.33,13.926,7.151,47.42,7.151,85.333v93.867
            c0,4.702-3.823,8.533-8.533,8.533c-4.71,0-8.533-3.831-8.533-8.533c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533
            v8.533c0,4.702-3.823,8.533-8.533,8.533s-8.533-3.831-8.533-8.533c0-4.71-3.814-8.533-8.533-8.533
            c-4.719,0-8.533,3.823-8.533,8.533v8.533c0,4.702-3.823,8.533-8.533,8.533s-8.533-3.831-8.533-8.533v-8.533
            c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533c0,4.702-3.823,8.533-8.533,8.533s-8.533-3.831-8.533-8.533v-102.4
            c0-4.71-3.814-8.533-8.533-8.533c-4.719,0-8.533,3.823-8.533,8.533v102.4c0,14.114,11.486,25.6,25.6,25.6
            c4.557,0,8.849-1.203,12.561-3.302C366.985,507.281,374.955,512,384,512s17.016-4.719,21.572-11.836
            c3.712,2.099,8.004,3.302,12.561,3.302c9.045,0,17.015-4.719,21.572-11.836c3.712,2.099,8.004,3.302,12.561,3.302
            c11.11,0,20.489-7.151,24.021-17.067h27.179c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-25.6v-85.333
            c0-12.023-0.469-23.509-1.169-34.133h26.769C508.186,341.333,512,337.51,512,332.8z"
            />
            <path
              d="M298.667,460.8h-25.6V341.333h8.533c4.719,0,8.533-3.823,8.533-8.533c0-4.71-3.814-8.533-8.533-8.533h-17.067
            c-4.719,0-8.533,3.823-8.533,8.533v136.533c0,4.71,3.814,8.533,8.533,8.533h34.133c4.719,0,8.533-3.823,8.533-8.533
            S303.386,460.8,298.667,460.8z"
            />
            <path
              d="M234.957,342.647l-80.802-51.021c-12.86-8.815-51.098-9.412-66.057,5.538c-3.328,3.336-3.328,8.738,0,12.066
            c3.337,3.337,8.738,3.337,12.066,0c8.499-8.482,38.042-7.851,44.612-3.345l77.09,48.683v99.089
            c-13.926,2.321-47.42,7.142-85.333,7.142H42.667c-4.71,0-8.533-3.831-8.533-8.533c0-4.702,3.823-8.533,8.533-8.533
            c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.71,0-8.533-3.831-8.533-8.533s3.823-8.533,8.533-8.533
            c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533H25.6c-4.71,0-8.533-3.831-8.533-8.533
            c0-4.702,3.823-8.533,8.533-8.533h8.533c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533
            c-4.71,0-8.533-3.831-8.533-8.533s3.823-8.533,8.533-8.533h102.4c4.719,0,8.533-3.823,8.533-8.533
            c0-4.71-3.814-8.533-8.533-8.533h-102.4c-14.114,0-25.6,11.486-25.6,25.6c0,4.565,1.203,8.849,3.302,12.561
            C4.727,366.976,0,374.955,0,384s4.727,17.024,11.836,21.572c-2.099,3.712-3.302,7.996-3.302,12.561
            c0,9.045,4.727,17.024,11.836,21.572c-2.099,3.712-3.302,7.996-3.302,12.561c0,11.11,7.159,20.489,17.067,24.021v27.179
            c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-25.6h85.333c12.023,0,23.509-0.469,34.133-1.169v26.769
            c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-28.151c25.745-2.483,43.221-5.922,44.339-6.153
            c3.985-0.794,6.861-4.292,6.861-8.363V349.867C238.933,346.94,237.431,344.218,234.957,342.647z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export { Teamwork };
