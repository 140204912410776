import React from 'react';

const ReactLeaflet = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="50 10 10 85.8"
      xmlSpace="preserve"
    >
      <title>React leaflet icon</title>
      <defs>
        <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4cf3c6" />
          <stop offset="1" stopColor="#0aa2e7" />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M46.75,52.15c0.54-0.44,1.08-0.86,1.62-1.26c-1.09-0.27-2.14-0.48-3.13-0.62c-0.06,1.03-0.08,2.1-0.04,3.22
        C45.69,53.04,46.21,52.59,46.75,52.15z"
        />
        <path
          d="M44.55,60.93c-0.14-0.67-0.26-1.34-0.37-2.03c-0.11-0.69-0.2-1.37-0.27-2.04c-0.78,0.82-1.48,1.63-2.1,2.42
        C42.64,59.84,43.55,60.4,44.55,60.93z"
        />
        <path
          d="M40.9,60.52c-2.33,3.35-3.08,6.17-2.08,7.42c0.85,1.06,2.98,1.12,5.83,0.18c0.58-0.19,1.18-0.42,1.79-0.68
        c-0.55-1.4-1.04-2.93-1.45-4.55C43.49,62.15,42.12,61.34,40.9,60.52z"
        />
        <path
          d="M43.73,54.86c-0.11-1.66-0.11-3.27-0.02-4.77c-4.03-0.36-6.83,0.4-7.4,1.9c-0.57,1.5,1.01,3.95,4.25,6.41
        C41.47,57.23,42.53,56.03,43.73,54.86z"
        />
        <path
          d="M54.08,47.34c-1.36-2.95-2.93-4.86-4.31-5.4c-0.36-0.14-0.7-0.18-1.02-0.13c-1.33,0.21-2.46,2.04-3.07,5.01
        c-0.13,0.61-0.23,1.24-0.31,1.91c1.46,0.21,3.01,0.54,4.63,1C51.38,48.8,52.76,48,54.08,47.34z"
        />
        <path
          d="M68.37,20.21c-0.33-0.93-0.66-1.69-1.01-2.56c-0.18-0.44-0.57-0.6-1.18-0.25c-0.75,0.42-1.48,0.77-2.39,1.26
        c-8.63,4.65-23.26,13.5-30.6,26.24c-6.4,11.38-11.17,31.7,8.63,35.56c20.52,9.72,29.11-4.72,31.01-23.58
        C74.05,44.58,72.06,30.47,68.37,20.21z M67.61,64.11c-0.88,2.31-4.33,3.28-8.97,2.87c-0.57,4.67-2.22,7.88-4.65,8.26
        c-0.6,0.1-1.2,0.02-1.81-0.21c-1.45-0.56-2.9-2.04-4.21-4.34c-0.33-0.57-0.64-1.19-0.94-1.84c-0.65,0.28-1.29,0.53-1.91,0.73
        c-2.5,0.82-4.56,0.95-6,0.39c-0.61-0.24-1.11-0.59-1.49-1.07c-1.55-1.93-0.65-5.42,2.02-9.28c-3.72-2.83-5.65-5.88-4.77-8.19
        c0.88-2.31,4.32-3.28,8.96-2.87c0.08-0.71,0.19-1.4,0.33-2.05c0.77-3.68,2.3-5.89,4.33-6.21c2.43-0.39,4.98,2.15,6.95,6.41
        c4.29-1.84,7.86-2,9.41-0.07c1.29,1.61,0.97,4.28-0.9,7.54c-0.33,0.58-0.7,1.16-1.11,1.75C66.57,58.75,68.49,61.8,67.61,64.11z"
        />
        <path
          d="M53.82,51.02c0.65,0.25,1.28,0.51,1.89,0.78c-0.31-1.09-0.66-2.11-1.03-3.05c-0.89,0.45-1.83,0.97-2.78,1.57
        C52.53,50.54,53.17,50.77,53.82,51.02z"
        />
        <path
          d="M48.69,64.52c-0.65-0.25-1.28-0.51-1.89-0.78c0.31,1.07,0.65,2.09,1.03,3.05c0.91-0.46,1.85-0.99,2.79-1.58
        C49.98,65,49.33,64.77,48.69,64.52z"
        />
        <path
          d="M61.95,57.15c-0.92,1.18-1.99,2.37-3.17,3.53c0.11,1.68,0.11,3.28,0.01,4.77c4.04,0.36,6.84-0.39,7.41-1.9
        C66.76,62.06,65.18,59.61,61.95,57.15z"
        />
        <path
          d="M62.65,53.4c1.51-2.63,1.88-4.74,1.03-5.8c-0.21-0.26-0.49-0.45-0.85-0.59c-1.38-0.53-3.81-0.17-6.78,1.1
        c0.55,1.38,1.04,2.9,1.45,4.54c1.5,0.75,2.88,1.55,4.1,2.38C62,54.48,62.35,53.94,62.65,53.4z"
        />
        <path
          d="M57.96,54.61c0.13,0.66,0.26,1.34,0.37,2.03c0.11,0.69,0.2,1.37,0.27,2.04c0.76-0.8,1.47-1.61,2.1-2.42
        C59.87,55.7,58.96,55.15,57.96,54.61z"
        />
        <path
          d="M48.43,68.21c0.28,0.61,0.57,1.18,0.87,1.71c1.5,2.63,3.13,4.02,4.46,3.8c1.57-0.25,2.89-2.86,3.38-6.92
        c-1.46-0.21-3.01-0.54-4.62-1C51.14,66.73,49.77,67.54,48.43,68.21z"
        />
        <path
          d="M55.76,63.39c-0.53,0.44-1.07,0.86-1.62,1.26c1.09,0.27,2.14,0.48,3.13,0.62c0.06-1.01,0.08-2.08,0.05-3.22
        C56.82,62.51,56.3,62.95,55.76,63.39z"
        />
        <path
          d="M57.2,60.03c-0.08-1.01-0.21-2.06-0.38-3.15l0,0c-0.17-1.08-0.38-2.14-0.61-3.16
        c-0.92-0.44-1.9-0.86-2.93-1.26c-0.6-0.23-1.19-0.44-1.77-0.64c-0.42-0.14-0.84-0.28-1.25-0.4c0,0,0,0,0,0c0,0,0,0,0,0
        c-0.06,0.04-0.12,0.09-0.18,0.13c-0.15,0.11-0.3,0.22-0.46,0.33c-0.02,0.02-0.04,0.03-0.06,0.05c-0.19,0.14-0.38,0.28-0.57,0.43
        c-0.12,0.09-0.24,0.19-0.36,0.28c-0.09,0.07-0.18,0.14-0.27,0.21c-0.21,0.17-0.43,0.34-0.64,0.51c-0.42,0.35-0.84,0.7-1.24,1.05
        c-0.05,0.04-0.09,0.08-0.14,0.12c-0.15,0.14-0.31,0.27-0.45,0.41c-0.01,0.01-0.01,0.01-0.02,0.02c-0.19,0.17-0.37,0.34-0.55,0.51
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0.02,0,0.03,0,0.05c0.08,1.01,0.21,2.06,0.38,3.15l0,0c0.17,1.08,0.38,2.14,0.61,3.16
        c0.92,0.44,1.9,0.86,2.93,1.26c0.6,0.23,1.19,0.44,1.77,0.64c0.42,0.14,0.84,0.28,1.25,0.4c0,0,0,0,0,0s0,0,0,0
        c0.06-0.04,0.12-0.09,0.18-0.13c0.15-0.11,0.3-0.22,0.46-0.33c0.02-0.02,0.04-0.03,0.06-0.05c0.19-0.14,0.38-0.28,0.57-0.43
        c0.12-0.09,0.24-0.19,0.36-0.28c0.09-0.07,0.18-0.14,0.27-0.21c0.21-0.17,0.43-0.34,0.64-0.51c0.42-0.35,0.84-0.7,1.24-1.05
        c0.05-0.04,0.09-0.08,0.14-0.12c0.15-0.14,0.31-0.27,0.46-0.41c0.01-0.01,0.01-0.01,0.02-0.02c0.19-0.17,0.37-0.34,0.55-0.51
        c0,0,0,0,0,0s0,0,0,0C57.2,60.06,57.2,60.04,57.2,60.03z M54.17,58.9c-0.62,1.63-2.42,2.44-4.04,1.81
        c-1.61-0.62-2.42-2.45-1.8-4.07c0.62-1.63,2.42-2.44,4.04-1.81C53.98,55.45,54.79,57.27,54.17,58.9z"
        />
      </g>
    </svg>
  );
};

export { ReactLeaflet };
