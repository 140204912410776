import React from 'react';

const Forked = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Github Forked</title>
      <defs>
        <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4cf3c6" />
          <stop offset="1" stopColor="#0aa2e7" />
        </linearGradient>
      </defs>
      <path d="M426.666667 234.666667C426.666667 152.32 359.68 85.333333 277.333333 85.333333S128 152.32 128 234.666667c0 68.352 46.421333 125.482667 109.184 143.146666 7.082667 50.474667 33.664 136.832 131.712 174.037334C469.333333 590.037333 469.333333 620.117333 469.333333 640v6.954667c-61.44 18.517333-106.666667 74.965333-106.666666 142.378666 0 82.346667 66.986667 149.333333 149.333333 149.333334s149.333333-66.986667 149.333333-149.333334c0-67.413333-45.226667-123.861333-106.666666-142.378666V640c0-19.882667 0-49.962667 100.437333-88.106667 98.048-37.205333 124.629333-123.562667 131.712-174.037333C849.578667 360.149333 896 303.018667 896 234.666667 896 152.32 829.013333 85.333333 746.666667 85.333333S597.333333 152.32 597.333333 234.666667c0 66.218667 43.605333 121.813333 103.424 141.354666-6.229333 31.36-24.106667 76.416-75.861333 96.085334-50.858667 19.285333-87.594667 40.661333-112.896 65.536-25.301333-24.874667-61.994667-46.250667-112.896-65.536-51.754667-19.669333-69.674667-64.725333-75.861333-96.085334C383.061333 356.48 426.666667 300.885333 426.666667 234.666667zM746.666667 170.666667c35.285333 0 64 28.714667 64 64S781.952 298.666667 746.666667 298.666667 682.666667 269.952 682.666667 234.666667 711.381333 170.666667 746.666667 170.666667z m-170.666667 618.666666c0 35.285333-28.714667 64-64 64s-64-28.714667-64-64 28.714667-64 64-64 64 28.714667 64 64zM277.333333 298.666667C242.048 298.666667 213.333333 269.952 213.333333 234.666667S242.048 170.666667 277.333333 170.666667 341.333333 199.381333 341.333333 234.666667 312.618667 298.666667 277.333333 298.666667z" />
    </svg>
  );
};

export { Forked };
