import React from 'react';

const Star = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1027 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Github Starred</title>
      <defs>
        <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4cf3c6" />
          <stop offset="1" stopColor="#0aa2e7" />
        </linearGradient>
      </defs>
      <path d="M636.843093 426.666667c-17.066667 0-34.133333-12.8-38.4-29.866667l-85.333333-230.4-85.333333 230.4c-8.533333 21.333333-34.133333 34.133333-55.466667 25.6C350.976427 418.133333 342.443093 392.533333 346.70976 371.2l123.733333-341.333333C478.976427 12.8 496.043093 0 513.10976 0s34.133333 12.8 38.4 29.866667l123.733333 341.333333c8.533333 21.333333-4.266667 46.933333-25.6 55.466667C645.376427 426.666667 641.10976 426.666667 636.843093 426.666667z" />
      <path d="M854.443093 1024c-8.533333 0-17.066667-4.266667-25.6-8.533333l-341.333333-251.733333c-17.066667-12.8-21.333333-42.666667-8.533333-59.733333 12.8-17.066667 42.666667-21.333333 59.733333-8.533333l226.133333 166.4L670.976427 597.333333c-8.533333-21.333333 4.266667-46.933333 25.6-55.466667 21.333333-8.533333 46.933333 4.266667 55.466667 25.6l145.066667 396.8c8.533333 17.066667 0 38.4-12.8 46.933333C871.50976 1019.733333 862.976427 1024 854.443093 1024z" />
      <path d="M709.376427 627.2c-12.8 0-25.6-4.266667-34.133333-17.066667-12.8-17.066667-8.533333-46.933333 8.533333-59.733333L854.443093 426.666667l-213.333333 0c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l341.333333 0c17.066667 0 34.133333 12.8 42.666667 29.866667 4.266667 17.066667 0 38.4-17.066667 46.933333l-273.066667 200.533333C726.443093 622.933333 717.90976 627.2 709.376427 627.2z" />
      <path d="M171.776427 1024c-8.533333 0-17.066667-4.266667-25.6-8.533333-17.066667-12.8-21.333333-29.866667-12.8-46.933333l132.266667-366.933333L18.176427 418.133333C5.376427 405.333333-3.156907 388.266667 1.10976 371.2 9.643093 354.133333 26.70976 341.333333 43.776427 341.333333l341.333333 0c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667L171.776427 426.666667 342.443093 550.4c17.066667 12.8 21.333333 29.866667 12.8 46.933333l-98.133333 264.533333 226.133333-166.4c17.066667-12.8 46.933333-8.533333 59.733333 8.533333 12.8 17.066667 8.533333 46.933333-8.533333 59.733333l-341.333333 251.733333C188.843093 1019.733333 180.30976 1024 171.776427 1024z" />
      <path d="M769.10976 426.666667l-128 0c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l128 0c25.6 0 42.666667 17.066667 42.666667 42.666667S794.70976 426.666667 769.10976 426.666667z" />
    </svg>
  );
};

export { Star };
