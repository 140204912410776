import React from 'react';

const CaseStudy = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Case Study icon</title>
      <path d="M355.697371 736.029257l36.253258-131.477943c3.035429-11.008 13.366857-28.759771 21.438171-36.827428L831.305143 149.803886a1.839543 1.839543 0 0 1 2.592914-0.0256l114.666057 114.669714a1.8432 1.8432 0 0 1-0.0256 2.592914L530.622171 684.957257c-7.753143 7.753143-25.183086 17.3568-35.84 19.755886l-139.081142 31.316114z m18.900115-207.096686c-14.782171 14.782171-29.973943 40.872229-35.5328 61.037715l-48.4096 175.572114c-5.504 19.968 12.2624 38.601143 32.468114 34.048l183.7056-41.358629c20.790857-4.681143 47.488-19.390171 62.584686-34.486857L987.326171 305.832229c22.133029-22.133029 22.162286-58.038857 0.0256-80.171886L872.682057 110.994286c-22.129371-22.133029-58.031543-22.114743-80.171886 0.0256L374.601143 528.932571z" />
      <path d="M744.788114 228.128914l127.901257 127.681829a27.428571 27.428571 0 1 0 38.7584-38.820572L783.542857 189.304686a27.428571 27.428571 0 1 0-38.754743 38.824228zM389.412571 594.066286l112.164572 112.164571a27.428571 27.428571 0 1 0 38.791314-38.791314l-112.164571-112.164572a27.428571 27.428571 0 1 0-38.791315 38.791315z" />
      <path d="M747.885714 702.208V778.971429c0 104.020114-84.319086 188.342857-188.342857 188.342857H76.803657c-1.013029 0-1.832229-908.767086-1.832228-908.767086 0-1.034971 671.082057-1.861486 671.082057-1.861486a27.428571 27.428571 0 1 0 0-54.857143H76.803657C45.483886 1.828571 20.114286 27.227429 20.114286 58.5472v906.9056c0 31.330743 25.373257 56.718629 56.689371 56.718629h482.742857c134.319543 0 243.196343-108.880457 243.196343-243.2v-76.763429a27.428571 27.428571 0 0 0-54.857143 0z" />
      <path d="M208.457143 257.828571h290.742857a27.428571 27.428571 0 1 0 0-54.857142H208.457143a27.428571 27.428571 0 0 0 0 54.857142zM208.457143 418.742857h170.057143a27.428571 27.428571 0 1 0 0-54.857143H208.457143a27.428571 27.428571 0 1 0 0 54.857143z" />
    </svg>
  );
};

export { CaseStudy };
